<template>
  <div class="page introductoryPage">
    <head-nav />
    <div class="main">
      <div class="auto wrap flexLr">
        <div class="userIntroductory space_between">
          <img class="userImg" :src="userImg" alt="" />
          <div class="introductory">
            <p v-for="(item, index) in introductory" :key="index">{{ item }}</p>
            <div class="chengguo">
              <span style="margin-bottom: 10px;display: block;">成果照片：</span>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-image
                    style="width: 100%"
                    :src="chengguo01[0]"
                    :preview-src-list="chengguo01"
                  />
                </el-col>
                <el-col :span="12">
                  <el-image
                    style="width: 100%"
                    :src="chengguo02[0]"
                    :preview-src-list="chengguo02"
                  />
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="userIntroductory space_between">
  
 </div> -->
    <foot />
  </div>
</template>

<script>
import headNav from "@/components/head.vue";
import foot from "@/components/foot.vue";

export default {
  name: "",
  data() {
    return {
      userImg: require("../../assets/images/user.jpg"),
      introductory: [
        "张宗亮，1963年5月出生，山东济南人；清华大学学士，天津大学博士；我国水工结构工程专家，中国工程院院士，全国工程勘察设计大师；现任中国电力建设集团公司首席科学家。在水利水电工程（含抽水蓄能）、新能源工程和数字工程等方面做出创新性贡献，获国家科技进步二等奖6项，省部级科技进步特等奖4项，一等奖7项。获光华工程科技奖、何梁何利科学与技术创新奖、杰出工程师奖和云南省杰出贡献奖，高坝研发团队获中央企业优秀创新团队；主持和组织设计建成糯扎渡、小湾、天生桥一级、红石岩、观音岩、黄登等25座大型水利水电工程；主持国家和省部级重点研发计划6项；主编行业标准5项；发明专利18项；软著31项；论文110篇；专著15部。",
      ],
      chengguo01: [require("../../assets/images/chengguo01.png")],

      chengguo02: [require("../../assets/images/chengguo02.png")],
    };
  },
  components: {
    headNav,
    foot,
  },
  mounted() {},
  methods: {},
};
</script>

<style>
.main {
  padding: 20px 0 34px;
}
.space_between {
  display: flex;
  justify-content: space-between;
}

.userIntroductory {
  width: 100%;
  padding: 10px;

  align-items: self-start;
}
.userIntroductory .userImg {
  width: 150px;
  height: auto;
}
.userIntroductory .introductory {
  width: calc(100% - 200px);
}
.introductory p {
  text-indent: 32px;
  line-height: 30px;
  margin-bottom: 10px;
}
</style>
